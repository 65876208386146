import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import TabBar from "./components/tab-bar/TabBar";
import "./App.scss";
import TabBarItem from "./components/tab-bar/tab-bar-item/TabBarItem";
import TabBarSpacer from "./components/tab-bar/TabBarSpacer";
import Image from './components/Image';
import { TwitchIcon } from "./components/twitch-icon/TwitchIcon";

class App extends React.Component {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<div className="main-wrapper">
				<div className="header">
					<div className="logo">
						<Image fileName="logo.png"/>
						<span>andreams_tv</span>
					</div>
				</div>
				<div className="twitch-banner">
					<a href="https://twitch.tv/andreams_tv" target="_blank" rel="noreferrer"><TwitchIcon /> Check out our Twitch channel!</a>
				</div>
				<TabBar>
					<TabBarItem tabItemClassName="home" tabItemPath="/" tabItemLabelText="Home" tabItemIconFilename="home.svg"/>
					<TabBarSpacer />
					<TabBarItem tabItemClassName="about-us" tabItemPath="/about-us" tabItemLabelText="About Us" tabItemIconFilename="question-mark.png"/>
				</TabBar>
				<div className="main-outlet">
					<Outlet />
				</div>
				<div className="footer-wrapper">
					<div className="footer">
						© 2022 <a href="https://linkedin.com/in/johnnybueti" target="_blank" style={{textDecoration: 'underline', marginLeft: '0.25rem'}} rel="noreferrer">Johnny Bueti</a>
					</div>
				</div>
				<div id="bottom-spacer">&nbsp;</div>
			</div>
		);
	}
}

export default App;