import React, { ReactNode } from "react";
import './AboutUsPage.scss';

class AboutUsPage extends React.Component {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<div className="page pageAboutUs">
				<div className="page-wrapper about-us-wrapper">
					<h1>A little on us!</h1>
					<p>
						Hi! This is <strong className="strong-2">Johnny & Ila</strong>, a gamer couple at heart based in Italy!
						<br />
						<br />
						We started streaming Animal Crossing: New Horizons on July 2020, as soon as <strong>@acnh_bot</strong>'s development was completed.
						<br />
						<br />
						<strong className="strong-1">Johnny</strong> is a 26 years old software engineer,
						<br />
						and <strong className="strong-2">Ila</strong> is a 20 years old student specializing in space engineering!
						<br />
						We love traveling, cooking, outer space, playing videogames, cats & dogs and, of course, eating: we are sushi and pizza powered! 🍣🍕
						<br />
						<br />
						Why do we do what we're doing? <strong>Community</strong>! Animal Crossing's community is the best we have ever had the pleasure to be part of, <br /> and helping others out is truly, really amazing.
						<br />
						We aim at improving our channel, making it grow and reach more and more people... and eventually move out together to the US!
					</p>
				</div>
			</div>
		);
	}
}

export default AboutUsPage;