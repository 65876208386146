import React from "react";

export default class Image extends React.Component<{ fileName: string } & React.ImgHTMLAttributes<HTMLImageElement>, { resolvedImagePath: string }> {
	public constructor(props: any) {
		super(props);

		this.setState(null);

		this.resolveImagePath(this.props.fileName).then(resolvedImagePath => {
			this.setState({
				resolvedImagePath: resolvedImagePath
			});
		});
	}

	public render(): React.ReactNode {
		return (
			<img src={this.state?.resolvedImagePath ?? 'null'} alt={this.props.alt} className={this.props.className}/>
		);
	}

	/**
	 * Given an image name, this resolves the dynamic image path.
	 */
	private async resolveImagePath(imageName: string): Promise<string> {
		return (await import(`../assets/img/${imageName}`)).default;
	}
};