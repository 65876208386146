import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import "./NotFoundPage.scss";

class NotFoundPage extends React.Component {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<div className="page pageNotFound">
				<div className="page-wrapper page-not-found-wrapper">
					<h1>Uh-oh!</h1>

					<p>The page you were looking for was not found!</p>

					<NavLink to='/'>
						<div className="button">Back</div>
					</NavLink>
				</div>
			</div>
		);
	}
}

export default NotFoundPage;