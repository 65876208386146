import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { SIGN_IN_WITH_TWITCH_URI } from "../../config";
import "./HomePage.scss";

class HomePage extends React.Component {
	public constructor(props: any) {
		super(props);

		this.signInWithTwitchDidTap = this.signInWithTwitchDidTap.bind(this);
	}

	private signInWithTwitchDidTap(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		window.location.href = SIGN_IN_WITH_TWITCH_URI + `&state=${encodeURI(window.location.href)}`;
	}

	public render(): ReactNode {
		return (
			<div className="page pageHome">
				<div className="page-wrapper home-wrapper">
					<h1>Welcome!</h1>

					<p>
						This is Johnny & Ila, we're <strong>andreams_tv</strong>!
						<br />
						We started streaming with the intention to provide fellow players with services aimed at easing the grind needed to gather materials, transform them, terraform etc., and focus on what is <strong className="strong-1">truly fun</strong>!
						<br />
						Welcome in!
					</p>

					<h2>Our Services</h2>
					<div className="services">
						<NavLink to="/"><div className="button-acnh button"><span>ACNH - Islands</span></div></NavLink>
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;