import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import Image from "../../Image";

export interface TabItemProps {
	tabItemClassName: string;
	tabItemLabelText: string;
	tabItemIconFilename: string;
	tabItemPath: string;
};

class TabBarItem extends React.Component<TabItemProps> {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<NavLink to={this.props.tabItemPath} className={({ isActive }) => { return isActive ? 'tab-item-selected' : '' }}>
				<div className={`tab-item tab-item-${this.props.tabItemClassName}`}>
					<div className="tab-item-wrapper">
						<Image fileName={this.props.tabItemIconFilename} className="tab-item-icon" alt={this.props.tabItemLabelText} />
						<label className="tab-item-label">{this.props.tabItemLabelText}</label>
					</div>	
				</div>
			</NavLink>
		);
	}
}

export default TabBarItem;